import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import { HashLink as Link } from 'react-router-hash-link';
import WatermarkImage from '../images/clients/GVR(5).png'; // Update this path to the actual image location

const AboutUs = () => {
    useDocTitle('GVR LIMITED | About Us');

    return (
        <>
            <div>
                <NavBar />
            </div>

            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                {/* Watermark */}
                <img
                    src={WatermarkImage}
                    alt="Watermark"
                    style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%', // Adjust size as needed
                        height: '100%', // Adjust size as needed
                        objectFit: 'contain',
                        opacity: 0.2, // Set opacity for subtle watermark effect
                        zIndex: 10,  // Ensure watermark is layered properly
                        pointerEvents: 'none', // Prevent watermark from blocking interactions
                    }}
                />

                <div className="flex flex-col items-center bg-gray-100 py-12 lg:py-24">
                    <div className="container mx-auto px-4 lg:px-20">
                        <div className="text-center mb-12">
                            <h1 className="text-4xl font-bold text-blue-900">About Us</h1>
                            <p className="text-lg text-gray-600 mt-4">Discover more about GVR LIMITED, our history, mission, team, and values.</p>
                        </div>

                        <section id="overview" className="my-12">
                            <h2 className="text-3xl font-bold text-blue-900">Company Overview</h2>
                            <p className="text-gray-700 mt-4">
                                GVR LIMITED, incorporated on 16 May 2024 (Company Number 15724516), is a dynamic and forward-thinking company based in London, England. Our headquarters are located at 5 Busby Place, London, NW5 2SP. We operate in the retail and food service sectors, specializing in:
                            </p>
                            <ul className="list-disc list-inside mt-4 text-gray-700">
                                <li>Other retail sales not conducted in stores, stalls, or markets (SIC 47990)</li>
                                <li>Take-away food shops and mobile food stands (SIC 56103)</li>
                            </ul>
                            <p className="text-gray-700 mt-4">
                                Our diverse range of services is designed to meet the evolving needs of our customers. Whether you are looking for unique retail products or convenient food options, GVR LIMITED aims to provide top-quality experiences tailored to your needs. We strive to blend innovation with quality, ensuring every product meets the high standards our customers expect.
                            </p>
                        </section>

                        <section id="history" className="my-12">
                            <h2 className="text-3xl font-bold text-blue-900">Our History</h2>
                            <p className="text-gray-700 mt-4">
                                GVR LIMITED was founded with the vision of transforming the retail and food service industry through a commitment to excellence and innovation. Over the years, we have grown from a small startup to a trusted name in the market.
                            </p>
                            <p className="text-gray-700 mt-4">
                                Our journey began with a small team dedicated to offering high-quality products and exceptional service. Through hard work, dedication, and a passion for what we do, we have expanded our offerings and established a loyal customer base. Notable milestones include the launch of our first flagship store in 2025 and receiving the Retail Excellence Award in 2026 for outstanding customer service.
                            </p>
                        </section>

                        <section id="mission" className="my-12">
                            <h2 className="text-3xl font-bold text-blue-900">Our Mission</h2>
                            <p className="text-gray-700 mt-4">
                                At GVR LIMITED, our mission is to enhance the everyday lives of our customers through innovative products and exceptional service. We are dedicated to:
                            </p>
                            <ul className="list-disc list-inside mt-4 text-gray-700">
                                <li>Delivering high-quality products and services that exceed expectations.</li>
                                <li>Building lasting relationships with our customers based on trust and reliability.</li>
                                <li>Fostering a culture of continuous improvement and innovation.</li>
                            </ul>
                            <p className="text-gray-700 mt-4">
                                Our commitment to excellence drives us to constantly evolve and adapt to market trends, ensuring that we remain at the forefront of our industry. We believe that by listening to our customers and adapting to their needs, we can continue to be a leader in our field.
                            </p>
                        </section>

                        <section id="team" className="my-12">
                            <h2 className="text-3xl font-bold text-blue-900">Meet the Team</h2>
                            <p className="text-gray-700 mt-4">
                                Our team at GVR LIMITED is composed of highly skilled and dedicated professionals who bring their expertise and passion to every aspect of our operations. We are led by:
                            </p>
                            <div className="bg-white p-8 rounded-lg shadow-lg mt-6">
                                <h3 className="text-2xl font-semibold text-blue-900">Maryam Ali SUFI</h3>
                                <p className="text-gray-700 mt-2">Director</p>
                                <p className="text-gray-500 mt-2">
                                    Maryam Ali SUFI has been a driving force behind GVR LIMITED since its inception. With extensive experience in business management and strategic leadership, Maryam is instrumental in guiding the company's vision and growth.
                                </p>
                                <p className="text-gray-500 mt-2">Date of Birth: February 1985</p>
                                <p className="text-gray-500 mt-2">Nationality: British</p>
                                <p className="text-gray-500 mt-2">Country of Residence: England</p>
                            </div>
                            <p className="text-gray-700 mt-4">
                                Our leadership team is complemented by a group of innovative thinkers and passionate individuals who share our vision for the future. Together, we work collaboratively to achieve our goals and drive the success of GVR LIMITED.
                            </p>
                        </section>

                        <section id="values" className="my-12">
                            <h2 className="text-3xl font-bold text-blue-900">Our Values</h2>
                            <p className="text-gray-700 mt-4">
                                At GVR LIMITED, our values define who we are and guide our actions. We are committed to:
                            </p>
                            <ul className="list-disc list-inside mt-4 text-gray-700">
                                <li>Integrity: Upholding the highest standards of honesty and transparency.</li>
                                <li>Customer Focus: Putting the needs and satisfaction of our customers at the center of everything we do.</li>
                                <li>Excellence: Striving for the highest quality in all our products and services.</li>
                                <li>Innovation: Embracing new ideas and technologies to enhance our offerings.</li>
                                <li>Teamwork: Fostering a collaborative environment where every team member contributes to our success.</li>
                            </ul>
                            <p className="text-gray-700 mt-4">
                                These values drive us to deliver outstanding results and make a positive impact in our community. We believe that by living these values, we can create a sustainable and responsible business that benefits everyone involved.
                            </p>
                        </section>

                        <section id="contact" className="my-12 bg-white p-8 rounded-lg shadow-lg">
                            <h2 className="text-3xl font-bold text-blue-900">Contact Us</h2>
                            <p className="text-gray-700 mt-4">
                                We welcome your inquiries, feedback, and suggestions. If you have any questions or need further information about our services, please do not hesitate to get in touch with us.
                            </p>
                            <div className="mt-6">
                                <Link
                                    to="/contact"
                                    className="inline-block text-sm font-bold tracking-wide bg-blue-900 text-gray-100 p-3 rounded-lg hover:bg-blue-700"
                                >
                                    Get in Touch
                                </Link>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default AboutUs;
