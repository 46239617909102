import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';

import { useDocTitle } from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import Careers from './pages/Careers';
import ApplyNow from './pages/ApplyNow';
import AboutUs from './pages/AboutUs';
import JobDescription from './pages/JobDescription';

function App() {
  const jobPosts = [
    {
      title: 'Sales Administrator',
      location: '77 New Road, Neath SA10 6HE',
      description: 'We are seeking a Sales Administrator to join our team. The ideal candidate will be organised, possess excellent communication skills, and have a strong background in sales administration and customer service. *Duties* - Provide administrative support to the sales team - Communicate with customers via phone and email - Utilise IT systems for sales administration tasks - Maintain accurate records of sales data - Assist in preparing sales reports using Word and Excel - Computerise sales orders and process invoices - Handle general administrative tasks to support the sales department.',
      responsibilities: [
        'Develop and maintain web applications.',
        'Collaborate with the design and product teams.',
        'Write clean and maintainable code.',
      ],
      qualifications: [
        'Strong organisational skills - Excellent communication abilities - Proficiency in Microsoft Outlook, Word, and Excel - Prior experience in sales administration is desirable - Competency in IT systems for data entry and record keeping'
      ]
    },
    {
      title: 'Shop Manager',
      location: '77 New rd, Skewen, Neath SA10 6HE',
      description: 'We are seeking a skilled Shop Manager to oversee our retail operations and ensure the smooth running of our shop. The ideal candidate will have excellent organisational skills and a flair for leadership.',
      responsibilities: [

        '- Manage day-to-day shop operations, including sales, inventory, and customer service',
        '- Supervise and train shop staff to deliver exceptional service',
        '- Communicate effectively with customers to understand their needs and provide assistance',
        '- Implement sales strategies to drive revenue and meet targets',

        '-Handle inventory management, including ordering supplies, managing stock levels and minimising waste.',

        '-Ensure compliance with health and safety regulations, address customer queries and complaints, manage cash drawers, record income and expenditures, and oversee inventory management.',
        '- Maintain a clean and organised shop environment',
        '- Handle customer queries and complaints in a professional manner'
      ],
      qualifications: [
        'Retail sales: 1 year (preferred)',
        'Supervising experience: 1 year (preferred)',
        'Customer service: 1 year (preferred)',
        'Retail management: 1 year (preferred)',
        'Management: 1 year (preferred)',
        'Driving Licence (preferred)'

      ]
    },
    {
      title: 'Marketing Executive - job post',
      location: '77 New Road, Neath SA10 6HE',
      description: 'We are seeking a dynamic Marketing Executive to join our team. The ideal candidate will be responsible for developing and implementing marketing strategies to promote our products and services effectively..',
      responsibilities: [
        '- Oversee and execute marketing campaigns, both online and offline',

        '- Manage email marketing campaigns to engage with customers',

        '- Coordinate marketing projects and activities',

        '- Utilise IT tools for data analysis and reporting',

        '- Communicate with various stakeholders to ensure marketing objectives are met',

        '- Demonstrate leadership in driving marketing initiatives forward',
      ],
      qualifications: [
        'Proficiency in English, both written and verbal',

        '- Strong project management and time management skills',

        '- Knowledge of office applications and Google tools',

        '- Previous experience in a marketing role is desirable',

        '- Ability to work collaboratively in a team environment',

        '- Excellent communication skills to interact with internal and external partners',
        'Bachelors (preferred)',

        ' Digital marketing: 1 year (preferred)',
        'Marketing: 1 year (preferred)'

      ]
    }
  ];
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("GVR LIMITED");

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/careers/:jobId" element={<JobDescription jobPosts={jobPosts} />} />
            <Route path="/apply" element={<ApplyNow />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/get-demo" element={<DemoProduct />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}


export default App;
