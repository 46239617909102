import React from 'react';
import { useParams, Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import WatermarkImage from '../images/clients/GVR(5).png'; // Import watermark image

const JobDescription = ({ jobPosts }) => {
    const { jobId } = useParams();
    const job = jobPosts[jobId]; // Fetch job based on index from params

    if (!job) {
        return <div>Job not found.</div>;
    }

    return (
        <>
            <div>
                <NavBar />
            </div>
            <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
                <div className="container mx-auto my-8 px-4 lg:px-20 relative">
                    {/* Card container */}
                    <div className='w-full bg-white p-8 my-4 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl relative'>
                        {/* Watermark image */}
                        <img
                            src={WatermarkImage}
                            alt="Watermark"
                            style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%', // or adjust size as needed
                                height: '100%', // or adjust size as needed
                                objectFit: 'contain',
                                opacity: 0.2, // Adjust opacity for watermark effect
                                zIndex: 0,  // Layer the watermark below the content
                                pointerEvents: 'none', // Ensures watermark doesn't block interactions
                            }}
                        />
                        <div className="relative z-10"> {/* Keep content above the watermark */}
                            <h2 className="text-2xl font-semibold text-blue-900">{job.title}</h2>
                            <p className="text-gray-600">{job.location}</p>
                            <p className="mt-2 text-gray-700">{job.description}</p>
                            <h3 className="mt-4 font-bold text-blue-900">Responsibilities:</h3>
                            <ul className="list-disc list-inside text-gray-700 mt-2">
                                {job.responsibilities.map((responsibility, i) => (
                                    <li key={i}>{responsibility}</li>
                                ))}
                            </ul>
                            <h3 className="mt-4 font-bold text-blue-900">Qualifications:</h3>
                            <ul className="list-disc list-inside text-gray-700 mt-2">
                                {job.qualifications.map((qualification, i) => (
                                    <li key={i}>{qualification}</li>
                                ))}
                            </ul>
                            <button className="mt-4 uppercase text-sm font-bold tracking-wide bg-blue-900 text-gray-100 p-3 rounded-lg w-full lg:w-1/2 focus:outline-none focus:shadow-outline hover:bg-blue-700">
                                <Link to={`/apply?jobTitle=${encodeURIComponent(job.title)}`}>
                                    Apply Now
                                </Link>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default JobDescription;
