import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Notiflix from 'notiflix';
import emailjs from 'emailjs-com';
import { storage } from '../components/firebase';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const ApplyNow = () => {
    useDocTitle('GVR LIMITED - Apply Now');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const jobTitle = queryParams.get('jobTitle');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [resume, setResume] = useState(null);
    const [coverLetter, setCoverLetter] = useState(null);
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState([]);

    const clearErrors = () => {
        setErrors([]);
    };

    const clearInput = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setResume(null);
        setCoverLetter(null);
        setMessage('');
    };

    const handleFileUpload = (e, setFile) => {
        setFile(e.target.files[0]);
    };

    const uploadFile = async (file) => {
        if (!file) return '';

        const storageRef = ref(storage, `uploads/${file.name}`);
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        return url;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        document.getElementById('submitBtn').disabled = true;
        document.getElementById('submitBtn').innerHTML = 'Submitting...';

        try {
            let resumeUrl = '';
            let coverLetterUrl = '';

            if (resume) {
                resumeUrl = await uploadFile(resume);
            }

            if (coverLetter) {
                coverLetterUrl = await uploadFile(coverLetter);
            }

            const templateParams = {
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone_number: phone,
                message: message,
                resume_url: resumeUrl,
                cover_letter_url: coverLetterUrl,
            };

            emailjs.send('service_z8pbggm', 'template_pze9l0m', templateParams, 'zlFIx_HCtRmSUmbk5')
                .then(response => {
                    document.getElementById('submitBtn').disabled = false;
                    document.getElementById('submitBtn').innerHTML = 'Submit Application';
                    clearInput();
                    Notiflix.Report.success(
                        'Application Submitted',
                        'Your application has been submitted successfully.',
                        'Okay'
                    );
                })
                .catch(error => {
                    document.getElementById('submitBtn').disabled = false;
                    document.getElementById('submitBtn').innerHTML = 'Submit Application';
                    Notiflix.Report.failure(
                        'Submission Error',
                        'There was an error submitting your application.',
                        'Okay'
                    );
                    console.error('Error sending email:', error);
                });

        } catch (error) {
            document.getElementById('submitBtn').disabled = false;
            document.getElementById('submitBtn').innerHTML = 'Submit Application';
            Notiflix.Report.failure(
                'Submission Error',
                'There was an error processing your files.',
                'Okay'
            );
        }
    };

    return (
        <>
            <div>
                <NavBar />
            </div>
            <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">

                    <form onSubmit={handleSubmit}>
                        <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                            <div className="flex">
                                <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">Apply Now</h1>
                            </div>
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                <div>
                                    <input
                                        name="first_name"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text"
                                        placeholder="First Name*"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors.first_name && (
                                        <p className="text-red-500 text-sm">{errors.first_name}</p>
                                    )}
                                </div>

                                <div>
                                    <input
                                        name="last_name"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text"
                                        placeholder="Last Name*"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors.last_name && (
                                        <p className="text-red-500 text-sm">{errors.last_name}</p>
                                    )}
                                </div>

                                <div>
                                    <input
                                        name="email"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="email"
                                        placeholder="Email*"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors.email && (
                                        <p className="text-red-500 text-sm">{errors.email}</p>
                                    )}
                                </div>

                                <div>
                                    <input
                                        name="phone_number"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="number"
                                        placeholder="Phone*"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors.phone_number && (
                                        <p className="text-red-500 text-sm">{errors.phone_number}</p>
                                    )}
                                </div>
                            </div>

                            <div className="mt-4">
                                <label className="block text-gray-700">Upload Resume*</label>
                                <input
                                    type="file"
                                    name="resume"
                                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    onChange={(e) => handleFileUpload(e, setResume)}
                                />
                                {errors.resume && (
                                    <p className="text-red-500 text-sm">{errors.resume}</p>
                                )}
                            </div>

                            <div className="mt-4">
                                <label className="block text-gray-700">Upload Cover Letter</label>
                                <input
                                    type="file"
                                    name="cover_letter"
                                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    onChange={(e) => handleFileUpload(e, setCoverLetter)}
                                />
                                {errors.cover_letter && (
                                    <p className="text-red-500 text-sm">{errors.cover_letter}</p>
                                )}
                            </div>

                            <div className="mt-4">
                                <textarea
                                    name="message"
                                    placeholder="Additional Information"
                                    className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    onKeyUp={clearErrors}
                                ></textarea>
                                {errors.message && (
                                    <p className="text-red-500 text-sm">{errors.message}</p>
                                )}
                            </div>

                            <div className="my-2 w-1/2 lg:w-2/4">
                                <button type="submit" id="submitBtn" className="uppercase text-sm font-bold tracking-wide bg-blue-900 text-gray-100 p-3 rounded-lg w-full 
                                    focus:outline-none focus:shadow-outline hover:bg-blue-700">
                                    Submit Application
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ApplyNow;
