// firebase.js
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBic9SnI6zrPi-d6KnzI72difxmQxHBe-A",
    authDomain: "gvrlimited.firebaseapp.com",
    projectId: "gvrlimited",
    storageBucket: "gvrlimited.appspot.com",
    messagingSenderId: "138973280366",
    appId: "1:138973280366:web:dc62ab8ba2d89625764e7f",
    measurementId: "G-8Y20NSL8H8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };
